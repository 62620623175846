import axios from "axios";
import {ALL_PERMISSION_URL, GET_ROLE_BY_ID, GET_ROLE_LIST, POST_ROLE_URL, UPDATE_ROLE_URL} from "../helpers/APIURL";

export default class RoleService {

    static getRolesList(params) {
        return axios.get(GET_ROLE_LIST, {params});
    }

    static addRole(data) {
        return axios.post(POST_ROLE_URL, data);
    }

    static getRoleById(id) {
        return axios.get(`${GET_ROLE_BY_ID}/${id}`);
    }

    static editRole(id, data) {
        return axios.put(`${UPDATE_ROLE_URL}/${id}`, data);
    }

    static getAllPermission() {
        return axios.get(ALL_PERMISSION_URL);
    }

}