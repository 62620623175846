import React from "react";
import GlobalContextProvider from "../contexts/GlobalContextProvider";
import AuthContextProvider from "../contexts/AuthContextProvider";
import UserContextProvider from "../contexts/UserContextProvider";
import RoleContextProvider from "../contexts/RoleContextProvider";
import ThemeContextProvider from "../contexts/ThemeContextProvider";
import SinglePageContextProvider from "../contexts/SinglePageContextProvider";
import ChatProvider from "../contexts/ChatContextProvider";
import Ride365ContextProvider from "../contexts/Ride365ContextProvider";

const contextProvider = [
    ThemeContextProvider,
    GlobalContextProvider,
    AuthContextProvider,
    UserContextProvider,
    RoleContextProvider,
    SinglePageContextProvider,
    ChatProvider,
    Ride365ContextProvider
]

export const ProviderInjection = ({app}) => {
    contextProvider.forEach(Provider => app = <Provider>{app}</Provider>);
    return app;
}

export default ProviderInjection;