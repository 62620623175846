import axios from "axios";
import {GET_SINGLE_PAGE_CONTENT, UPDATE_SINGLE_PAGE_CONTENT} from "../../helpers/APIURL";

export default class SinglePageContentService {

    static getSinglePageContent(params) {
        return axios.get(GET_SINGLE_PAGE_CONTENT, {params})
    }

    static updateSinglePageContent(data) {
        return axios.put(`${UPDATE_SINGLE_PAGE_CONTENT}`, data);
    }
}