const Permission = {
    ALL: 'ALL',
    DASHBOARD_USER_GRAPH: "DASHBOARD_USER_GRAPH",
    DASHBOARD_CUSTOMER_COUNT: "DASHBOARD_CUSTOMER_COUNT",
    DASHBOARD_ALL_PROPERTY_COUNT: "DASHBOARD_ALL_PROPERTY_COUNT",

    READ_ALL_USER: "READ_ALL_USER",
    READ_USER: "READ_USER",
    CREATE_USER: "CREATE_USER",
    MODIFY_USER: "MODIFY_USER",
    DELETE_USER: "DELETE_USER",
    READ_USER_PROFILE: "READ_USER_PROFILE",
    MODIFY_USER_PROFILE: "MODIFY_USER_PROFILE",
    UPLOAD_USER_IMAGE: "UPLOAD_USER_IMAGE",

    IMAGE_UPLOAD: "IMAGE_UPLOAD",

    READ_FIREBASE_DEVICE_REGISTRATION: "READ_FIREBASE_DEVICE_REGISTRATION",

    READ_PUSH_NOTIFICATION: "READ_PUSH_NOTIFICATION",
    CREATE_PUSH_NOTIFICATION: "CREATE_PUSH_NOTIFICATION",
    MODIFY_PUSH_NOTIFICATION: "MODIFY_PUSH_NOTIFICATION",
    DELETE_PUSH_NOTIFICATION: "DELETE_PUSH_NOTIFICATION",

    READ_ROLE: "READ_ROLE",
    CREATE_ROLE: "CREATE_ROLE",
    MODIFY_ROLE: "MODIFY_ROLE",
    DELETE_ROLE: "DELETE_ROLE",

    READ_PERMISSION: "READ_PERMISSION",
    UPDATE_USER_PASSWORD: "UPDATE_USER_PASSWORD",
    UPDATE_PROFILE_PASSWORD: "UPDATE_PROFILE_PASSWORD",

    READ_ADDRESS: "READ_ADDRESS",
    CREATE_ADDRESS: "CREATE_ADDRESS",
    MODIFY_ADDRESS: "MODIFY_ADDRESS",
    DELETE_ADDRESS: "DELETE_ADDRESS",
    READ_PERSONAL_ADDRESS: "READ_PERSONAL_ADDRESS",
    CREATE_PERSONAL_ADDRESS: "CREATE_PERSONAL_ADDRESS",
    MODIFY_PERSONAL_ADDRESS: "MODIFY_PERSONAL_ADDRESS",
    DELETE_PERSONAL_ADDRESS: "DELETE_PERSONAL_ADDRESS",

    READ_MANDATORY_FIELD: "READ_MANDATORY_FIELD",
    CREATE_MANDATORY_FIELD: "CREATE_MANDATORY_FIELD",
    MODIFY_MANDATORY_FIELD: "MODIFY_MANDATORY_FIELD",
    DELETE_MANDATORY_FIELD: "DELETE_MANDATORY_FIELD",

    READ_SPECIAL_PRICE: "READ_SPECIAL_PRICE",
    CREATE_SPECIAL_PRICE: "CREATE_SPECIAL_PRICE",
    MODIFY_SPECIAL_PRICE: "MODIFY_SPECIAL_PRICE",
    DELETE_SPECIAL_PRICE: "DELETE_SPECIAL_PRICE",

    READ_ADVERTISEMENT: "READ_ADVERTISEMENT",
    CREATE_ADVERTISEMENT: "CREATE_ADVERTISEMENT",
    MODIFY_ADVERTISEMENT: "MODIFY_ADVERTISEMENT",
    DELETE_ADVERTISEMENT: "DELETE_ADVERTISEMENT",
    VERIFY_ADVERTISEMENT: "VERIFY_ADVERTISEMENT",

    READ_PROMO: "READ_PROMO",
    CREATE_PROMO: "CREATE_PROMO",
    MODIFY_PROMO: "MODIFY_PROMO",
    DELETE_PROMO: "DELETE_PROMO",

    READ_PACKAGE: "READ_PACKAGE",
    READ_PERSONAL_PACKAGE: "READ_PERSONAL_PACKAGE",
    CREATE_PACKAGE: "CREATE_PACKAGE",
    MODIFY_PACKAGE: "MODIFY_PACKAGE",
    DELETE_PACKAGE: "DELETE_PACKAGE",
    ADD_PACKAGE_TO_HOST_PROFILE: "ADD_PACKAGE_TO_HOST_PROFILE",

    READ_TRANSACTION: "READ_TRANSACTION",
    CREATE_TRANSACTION: "CREATE_TRANSACTION",
    MODIFY_TRANSACTION: "MODIFY_TRANSACTION",
    DELETE_TRANSACTION: "DELETE_TRANSACTION",

    READ_BANK_INFO: "READ_BANK_INFO",
    CREATE_BANK_INFO: "CREATE_BANK_INFO",
    MODIFY_BANK_INFO: "MODIFY_BANK_INFO",
    DELETE_BANK_INFO: "DELETE_BANK_INFO",

    READ_GLOBAL_CONFIG: "READ_GLOBAL_CONFIG",
    CREATE_GLOBAL_CONFIG: "CREATE_GLOBAL_CONFIG",
    MODIFY_GLOBAL_CONFIG: "MODIFY_GLOBAL_CONFIG",
    DELETE_GLOBAL_CONFIG: "DELETE_GLOBAL_CONFIG",

    READ_BLOG: "READ_BLOG",
    READ_BLOG_COUNT: "READ_BLOG_COUNT",
    CREATE_BLOG: "CREATE_BLOG",
    MODIFY_BLOG: "MODIFY_BLOG",
    DELETE_BLOG: "DELETE_BLOG",

    READ_CAREER: "READ_CAREER",
    CREATE_CAREER: "CREATE_CAREER",
    MODIFY_CAREER: "MODIFY_CAREER",
    DELETE_CAREER: "DELETE_CAREER",
    READ_CAREER_APPLICATION: "READ_CAREER_APPLICATION",
    DELETE_CAREER_APPLICATION: "DELETE_CAREER_APPLICATION",

    READ_ADDITIONAL_PAGE: "READ_ADDITIONAL_PAGE",
    CREATE_ADDITIONAL_PAGE: "CREATE_ADDITIONAL_PAGE",
    MODIFY_ADDITIONAL_PAGE: "MODIFY_ADDITIONAL_PAGE",
    DELETE_ADDITIONAL_PAGE: "DELETE_ADDITIONAL_PAGE",

    READ_CUSTOMER_REPORT: "READ_CUSTOMER_REPORT",
    CREATE_CUSTOMER_REPORT: "CREATE_CUSTOMER_REPORT",
    MODIFY_CUSTOMER_REPORT: "MODIFY_CUSTOMER_REPORT",
    DELETE_CUSTOMER_REPORT: "DELETE_CUSTOMER_REPORT",

    BOOKING_INCOME_COUNT: "BOOKING_INCOME_COUNT",
    READ_ALL_PERSONAL_COUNT: "READ_ALL_PERSONAL_COUNT",

    READ_AMENITY: "READ_AMENITY",
    CREATE_AMENITY: "CREATE_AMENITY",
    MODIFY_AMENITY: "MODIFY_AMENITY",
    DELETE_AMENITY: "DELETE_AMENITY",

    READ_ALL_BOOKING: "READ_ALL_BOOKING",
    READ_BOOKING: "READ_BOOKING",
    READ_USER_BOOKING: "READ_USER_BOOKING",
    READ_HOST_BOOKING: "READ_HOST_BOOKING",
    CREATE_BOOKING: "CREATE_BOOKING",
    CASH_PAYMENT_BOOKING: "CASH_PAYMENT_BOOKING",
    CANCEL_BOOKING: "CANCEL_BOOKING",
    MODIFY_BOOKING: "MODIFY_BOOKING",
    DELETE_BOOKING: "DELETE_BOOKING",
    READ_BOOKING_STATES: "READ_BOOKING_STATES",
    MODIFY_BOOKING_STATES: "MODIFY_BOOKING_STATES",

    APPLY_PROMO: "APPLY_PROMO",
    REMOVE_PROMO: "REMOVE_PROMO",
    ADJUST_POINT: "ADJUST_POINT",
    REMOVE_POINT: "REMOVE_POINT",

    READ_REVIEW: "READ_REVIEW",
    CREATE_REVIEW: "CREATE_REVIEW",
    MODIFY_REVIEW: "MODIFY_REVIEW",
    DELETE_REVIEW: "DELETE_REVIEW",

    READ_TYPE: "READ_TYPE",
    CREATE_TYPE: "CREATE_TYPE",
    MODIFY_TYPE: "MODIFY_TYPE",
    DELETE_TYPE: "DELETE_TYPE",

    READ_FAVOURITE: "READ_FAVOURITE",
    MODIFY_FAVOURITE: "MODIFY_FAVOURITE",

    READ_PERSONAL_ADVERTISEMENT: 'READ_PERSONAL_ADVERTISEMENT',
    MODIFY_PERSONAL_ADVERTISEMENT: 'MODIFY_PERSONAL_ADVERTISEMENT',

    READ_SUBSCRIBER: 'READ_SUBSCRIBER',

    READ_PROMOTIONAL_DATA: 'READ_PROMOTIONAL_DATA',
    CREATE_PROMOTIONAL_DATA: 'CREATE_PROMOTIONAL_DATA',
    MODIFY_PROMOTIONAL_DATA: 'MODIFY_PROMOTIONAL_DATA',
    DELETE_PROMOTIONAL_DATA: 'DELETE_PROMOTIONAL_DATA',
    READ_EMERGENCY_CONTACT: 'READ_EMERGENCY_CONTACT',
    CREATE_EMERGENCY_CONTACT: 'CREATE_EMERGENCY_CONTACT',
    MODIFY_EMERGENCY_CONTACT: 'MODIFY_EMERGENCY_CONTACT',
    DELETE_EMERGENCY_CONTACT: 'DELETE_EMERGENCY_CONTACT',

    READ_BOOKING_STATES_USER_PERSONAL: 'READ_BOOKING_STATES_USER_PERSONAL',
    READ_BOOKING_STATES_HOST_PERSONAL: 'READ_BOOKING_STATES_HOST_PERSONAL',
    MODIFY_BOOKING_STATES_USER_PERSONAL: 'MODIFY_BOOKING_STATES_USER_PERSONAL',
    MODIFY_BOOKING_STATES_HOST_PERSONAL: 'MODIFY_BOOKING_STATES_HOST_PERSONAL',

    READ_CHAT: 'READ_CHAT',

    REFUND_REQUEST: 'REFUND_REQUEST',

    REQUESTED_HOST: 'REQUESTED_HOST',
    APPROVED_HOST_REQUEST: 'APPROVED_HOST_REQUEST',
    CANCEL_HOST_REQUEST: 'CANCEL_HOST_REQUEST',

    READ_ALL_HOST: 'READ_ALL_HOST',

}

export default Permission;
