import React, {useContext} from "react";
import {AuthContext} from "./contexts/AuthContextProvider";
import axios from "axios";
import {useNavigate} from "react-router-dom";
import {PAGE_404_PATH, PAGE_500_PATH} from "./routes/Slugs";
import {getAuthHeaders} from "./helpers/Utils";

const Interceptors = () => {

    const navigate = useNavigate();
    const authContext = useContext(AuthContext);

    // Add a request interceptor
    axios.interceptors.request.use(function (config) {

        // Do something before request is sent
        if (!config.headers.Authorization) {
            config.headers = {...config.headers, ...getAuthHeaders()};
        }

        return config;
    }, function (error) {
        // Do something with request error

        return Promise.reject(error);
    });

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {

        // Do something with response data

        return response;
    }, function (error) {

        // Do something with response error
        if (error.response && error.response.status === 401) {
            authContext.logout();
        }

        if (error.response && error.response.status === 403) {
            // navigate(PAGE_403_PATH);
            authContext.logout();
        }

        if (error.response && error.response.status === 404) {
            navigate(PAGE_404_PATH);
        }

        if (error.response && error.response.status === 500) {
            navigate(PAGE_500_PATH);
        }

        return Promise.reject(error);
    });

    return (<></>);
}

export default Interceptors;
