import React, {createContext, useState} from 'react';
import GarageTypesService from "../services/PropertyTypes/Ride365/GarageTypesService";
import {getErrorMessage} from "../helpers/Utils";
import {Toast} from "../components/common/Toast";
import VehcileTypesService from "../services/PropertyTypes/Ride365/VehcileTypesService";

export const Ride365Context = createContext("Ride365Context");

const Ride365ContextProvider = ({children}) => {
    const [garageTypeLoading, setGarageTypeLoading] = useState(false);
    //
    const [garageTypeList, setGarageTypeList] = useState([]);
    const [vehicleTypeList, setVehicleTypeList] = useState([]);
    const [vehicleTypeLoading, setVehicleTypeLoading] = useState(false);


    const getAllGarageType = async params => {
        try {
            setGarageTypeLoading(true);
            const res = await GarageTypesService.getGarageTypes(params);
            setGarageTypeList(res.data.content);
            setGarageTypeLoading(false);
            return true;
        } catch (error) {
            setGarageTypeLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }

    const getAllVehicleType = async params => {
        try {
            setVehicleTypeLoading(true);
            const res = await VehcileTypesService.getVehicleTypes(params);
            setVehicleTypeList(res.data.content);
            setVehicleTypeLoading(false);
            return true;
        } catch (error) {
            setVehicleTypeLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            return false;
        }
    }



    return (
        <Ride365Context.Provider
            value={{
                garageTypeLoading,
                vehicleTypeLoading,
                garageTypeList,
                vehicleTypeList,
                getAllGarageType,
                getAllVehicleType,
            }}
        >
            {children}
        </Ride365Context.Provider>
    );
}

export default Ride365ContextProvider;