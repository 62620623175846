import React, {createContext, useState} from 'react';
import UserService from "../services/UserService";
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";
import HostServices from "../services/AmenityGroup/HostServices";

export const UserContext = createContext("UserContext");

const UserContextProvider = ({children}) => {

    const [userList, setUserList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingUserList, setLoadingUserList] = useState(false);
    const [hostList, setHostList] = useState([]);
    const [hostLoading, setHostLoading]= useState(false);
    // const [totalHostElements, setTotalHostElements] = useState(0);

    const getUserList = async (params) => {
        try {
            setLoadingUserList(true);
            const response = await UserService.getUsersList(params);
            setUserList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingUserList(false);

        } catch (error) {
            setLoadingUserList(false);
            const message = getErrorMessage(error);
            Toast('error', "Error", message);
        }
    }

    const getHostList = async (params) => {
        try {
            setHostLoading(true);
            const response = await HostServices.getHostList(params);
            setHostList(response.data.content);
            setTotalElements(response.data.totalElements);
            setHostLoading(false);

        } catch (error) {
            setHostLoading(false);
            const message = getErrorMessage(error);
            Toast('error', "Error", message);
        }
    }


    return (
        <UserContext.Provider
            value={{
                getUserList,
                userList,
                loadingUserList,
                totalElements,
                getHostList,
                hostList,
                hostLoading,
                // totalHostElements
            }}
        >
            {children}
        </UserContext.Provider>
    );
}

export default UserContextProvider;