import axios from "axios";
import {
    ADD_PROPERTY_TYPES_VEHICLE_PATH,
    DELETE_PROPERTY_TYPES_VEHICLE_BY_ID_PATH,
    GET_PROPERTY_TYPES_VEHICLE_BY_ID_PATH,
    GET_PROPERTY_TYPES_VEHICLE_PATH,
    UPDATE_PROPERTY_TYPES_VEHICLE_BY_ID_PATH
} from "../../../helpers/APIURL";

export default class VehcileTypesService {

    static getVehicleTypes(params) {
        return axios.get(GET_PROPERTY_TYPES_VEHICLE_PATH, {params});
    }

    static addPVehicleTypesPropertyTypes(data) {
        return axios.post(ADD_PROPERTY_TYPES_VEHICLE_PATH, data);
    }


    static getVehicleTypesById(id) {
        return axios.get(`${GET_PROPERTY_TYPES_VEHICLE_BY_ID_PATH}/${id}`);
    }

    static editVehicleTypesById(id, data) {
        return axios.put(`${UPDATE_PROPERTY_TYPES_VEHICLE_BY_ID_PATH}/${id}`, data);
    }

    static deleteVehicleTypes(id) {
        return axios.delete(`${DELETE_PROPERTY_TYPES_VEHICLE_BY_ID_PATH}/${id}`);
    }
}