import axios from "axios";
import {
    CHANGE_PASSWORD_URL,
    CREATE_USER_URL,
    GET_USER_BY_ID,
    GET_USER_LIST,
    GET_USER_PROFILE,
    UPDATE_USER_PROFILE_URL,
    UPDATE_USER_URL
} from "../helpers/APIURL";

export default class UserService {

    static getUsersList(params) {
        return axios.get(GET_USER_LIST, {params})
    }

    static addUser(data) {
        return axios.post(`${CREATE_USER_URL}`, data);
    }

    static updateUser(data, id) {
        return axios.put(`${UPDATE_USER_URL}/${id}`, data);
    }

    static changeUserPassword(data, id) {
        return axios.put(`${CHANGE_PASSWORD_URL}/${id}`, data);
    }

    static changeProfilePassword(data) {
        return axios.put(CHANGE_PASSWORD_URL, data);
    }

    static getUserById(id) {
        return axios.get(`${GET_USER_BY_ID}/${id}`);
    }

    static getUserProfile() {
        return axios.get(GET_USER_PROFILE);
    }

    static updateUserProfile(data) {
        return axios.put(UPDATE_USER_PROFILE_URL, data);
    }

}