import React, {lazy, Suspense, useContext} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import LoadingSuspense from "./components/common/LoadingSuspense";
import PrivateRoute from "./components/common/PrivateRoute";
import {
    FORGOT_PASSWORD,
    LOGIN_PATH,
    OTP_PATH,
    PAGE_403_PATH,
    PAGE_404_PATH,
    PAGE_500_PATH,
    RESET_PASSWORD_PATH
} from "./routes/Slugs";
import {AuthContext} from "./contexts/AuthContextProvider";
import Interceptors from "./Interceptors";

const DefaultLayout = lazy(() => import('./components/layout/DefaultLayout'));
const WrappedLogin = lazy(() => import('./pages/login/Login'));
const Page403 = lazy(() => import('./pages/error_pages/Page403'));
const Page404 = lazy(() => import('./pages/error_pages/Page404'));
const Page500 = lazy(() => import('./pages/error_pages/Page500'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword/ForgotPassword'));
const OtpVerify = lazy(() => import('./pages/ForgotPassword/Otp'));
const ResetPassword = lazy(() => import('./pages/ForgotPassword/ResetPassword'));

const App = () => {

    const {isLogin} = useContext(AuthContext);

    return (
        <div className="app-wrapper">
            <Suspense fallback={<LoadingSuspense/>}>
                <BrowserRouter>
                    <Routes>
                        <Route path={LOGIN_PATH} element={<WrappedLogin/>}/>
                        <Route path={FORGOT_PASSWORD} element={<ForgotPassword/>}/>
                        <Route path={OTP_PATH} element={<OtpVerify/>}/>
                        <Route path={RESET_PASSWORD_PATH} element={<ResetPassword/>}/>
                        <Route element={<PrivateRoute isLogin={isLogin}/>}>
                            <Route path={PAGE_404_PATH} element={<Page404/>}/>
                            <Route path={PAGE_403_PATH} element={<Page403/>}/>
                            <Route path={PAGE_500_PATH} element={<Page500/>}/>
                            <Route path="*" element={<DefaultLayout/>}/>
                        </Route>
                    </Routes>
                    <Interceptors/>
                </BrowserRouter>
            </Suspense>
        </div>
    );
}

export default App;
