import React, {createContext, useState} from 'react';
import {ACCESS_TOKEN, PROFILE} from '../helpers/APIURL';
import AuthService from "../services/AuthService";
import {Toast} from "../components/common/Toast";
import UserService from "../services/UserService";
import {getErrorMessage} from "../helpers/Utils";

export const AuthContext = createContext("AuthContext");

const auth = localStorage.getItem(ACCESS_TOKEN);
const user = localStorage.getItem(PROFILE);

const AuthContextProvider = ({children}) => {

    const [isLogin, setIsLogin] = useState(!!auth);
    const [profile, setProfile] = useState(user ? JSON.parse(user) : null);
    const [loading, setLoading] = useState(false);
    const [profileLoading, setProfileLoading] = useState(false);

    const login = async (credential) => {

        try {
            setLoading(true);
            const response = await AuthService.login(credential);
            localStorage.setItem(ACCESS_TOKEN, response.data.token.access);
            setIsLogin(true);
            setLoading(false);
        } catch (error) {
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            setLoading(false);
            setIsLogin(false);
        }

    }

    const getUserProfile = async () => {

        try {
            setProfileLoading(true);
            const response = await UserService.getUserProfile();

            const _data = response.data;
            _data.fullName = _data.info.fullName;

            localStorage.setItem(PROFILE, JSON.stringify(_data));
            setProfile(_data);
            setProfileLoading(false);
        } catch (error) {
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
            setProfileLoading(false);
        }
    }

    const logout = () => {
        setIsLogin(false);
        localStorage.clear();
        setProfile([]);
    }

    return (
        <AuthContext.Provider
            value={{
                isLogin,
                profile,
                loading,
                profileLoading,
                setProfile,
                permissions: profile ? profile.permissions : [],
                login,
                logout,
                getUserProfile
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export default AuthContextProvider;