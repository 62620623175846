import axios from "axios";
import {GET_HOST_LIST_URL, GET_HOST_REQUEST_URL, HOST_APPROVE_URL, HOST_CANCEL_URL} from "../../helpers/APIURL";

export default class HostServices {

    static getHostList(params) {
        return axios.get(GET_HOST_LIST_URL, {params})
    }

    static getHostRequest(params) {
        return axios.get(GET_HOST_REQUEST_URL, {params})
    }

    static hostApprove(params, id) {
        return axios.put(`${HOST_APPROVE_URL}/${id}`, null, {params});
    }

    static hostCancel(id) {
        return axios.put(`${HOST_CANCEL_URL}/${id}`);
    }
}