import React, {createContext, useState} from 'react';
import RoleService from "../services/RoleService";
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";

export const RoleContext = createContext("RoleContext");

const RoleContextProvider = ({children}) => {

    const [roleList, setRoleList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingRoleList, setLoadingRoleList] = useState(false);

    const getRoleList = async (params) => {
        try {
            setLoadingRoleList(true);
            const response = await RoleService.getRolesList(params);
            setRoleList(response.data.content);
            setTotalElements(response.data.totalElements);
            setLoadingRoleList(false);
        } catch (error) {
            setLoadingRoleList(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
        }

    }

    return (
        <RoleContext.Provider
            value={{
                getRoleList,
                roleList,
                totalElements,
                loadingRoleList,
            }}
        >
            {children}
        </RoleContext.Provider>
    );
}

export default RoleContextProvider;