import {useLocation} from 'react-router-dom';
import Permission from "./Permission";
import {ACCESS_TOKEN, BD_TK_SIGN, REACT_APP_S3_BUCKET_URL} from "./APIURL";
import moment from "moment";
// var BASE64_MARKER = ';base64,';
export const CURRENCY = "৳";

export const getErrorMessage = (error) => {
    if (error.response) {
        if (error.response.data) {
            return error.response.data.message;
        }

        return error.response.data;
    } else {
        return error.message;
    }
}

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const getParams = (query, data) => {
    for (const key of Object.keys(data)) {
        data = {...data, [key]: query.get(key) || ""};
    }
    return data;
}

export const priceFormatter = price => {
    if (!price || price === 0) return `${CURRENCY} 0.0`;
    return `${CURRENCY} ${price.toLocaleString("en")}`;
}

export const isValueExistInSearch = (data) => {

    for (const key of Object.keys(data)) {
        if (data[key]) {
            return ["1"];
        }
    }
    return ["0"];
}

export const getBase64Image = (data) => {
    const base64 = btoa(
        new Uint8Array(data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
        ),
    );

    return "data:;base64," + base64;
}

export const bindUrlWithParams = (url, params) => {
    let result = url;

    Object.keys(params).forEach(key => {
        if (!params[key]) {
            delete params[key];
        }
    });

    Object.keys(params).forEach((key, index) => {

        if (index === 0) {
            result += `?${key}=${params[key]}`;
        } else {
            result += `&${key}=${params[key]}`;
        }

    });


    return result;
}

export const getFullPreviousRoute = (history) => {
    return `${history.location.pathname}${history.location.search}`;
}

export const resetState = (data) => {
    for (const key of Object.keys(data)) {
        data = {...data, [key]: ""}
    }
    return data;
}

export const getAllNonObjectValues = (data) => {

    let singleValue = {};

    for (const [key, value] of Object.entries(data)) {
        if (typeof value !== "object") {
            singleValue[key] = value;
        }
    }
    return singleValue;
}

export const getAllNestedObjectValues = (data) => {
    let singleValue = [];

    for (const value of Object.values(data)) {
        if (value && typeof value === "object") {
            singleValue.push(value);
        }
    }
    return singleValue
}

export const getPercentageValue = (value, percent) => {
    return (value * percent) / 100;
}

export const hasPermission = (loggedInUserPermissions, permissions) => {

    if (!permissions) {
        return false;
    }

    if (permissions.includes(Permission.ALL)) {
        return true;
    }

    if (!loggedInUserPermissions) {
        return false;
    }

    for (const permission of permissions) {

        if (loggedInUserPermissions.includes(permission)) {
            return true;
        }

    }

    return false;
}


export const dateTimeFormatter = date => {
    if (!date) return;
    return moment.utc(date, "DD-MM-YYYY HH:mm:ss").local().format("D MMM YYYY HH:mm A");
}


export const dateFormatter = date => {
    if (!date) return;
    return moment.utc(date, "DD-MM-YYYY HH:mm:ss").local().format("D MMM YYYY");
}

export const dateTimeFormatter2 = date => {
    if (!date) return;
    return moment(date, "DD-MM-YYYY HH:mm:ss").format("D MMM YYYY hh:mm:ss");
}

export const getImageURL = url => {
    if (!url) return null;
    return `${REACT_APP_S3_BUCKET_URL}/${url}`;
}
// export const dateFormatterDateMonthYear = date => {
//     if (!date) return;
//     return moment.utc(date, "DD-MM-YYYY HH:mm:ss").local().format("DD MMM YYYY");
// }

export const dateFormatterDateMonthYear = date => {
    if (!date) return;
    return moment.utc(date, "DD-MM-YYYY HH:mm:ss").local().format("DD MMM YYYY HH:mm A");
}

export const propertyDateTime = date => {
    if (!date) return;
    return moment.utc(longToDate(date), "DD-MM-YYYY HH:mm:ss").local().format("DD MMM YYYY HH:mm A")
}

export const dateTimeFormatterDateMonthYear = date => {
    if (!date) return;
    return moment.utc(date, "DD-MM-YYYY HH:mm:ss").local().format("DD MMM YYYY HH:mm A");
}
export const dateFormatterForTableSorting = date => {
    if (!date) return;
    return moment.utc(date, "DD-MM-YYYY HH:mm:ss").local().format("DD MMM YYYY HH:mm A");
}

export const dateFormatter4 = date => {
    if (!date) return;
    return moment.utc(date, "DD-MM-YYYY HH:mm:ss").local().format("DD-MM-YYYY");
}

export const dateFormatter2 = date => {
    if (!date) return;
    return moment.utc(date, "DD-MM-YYYY HH:mm:ss").local().format("YYYY-MM-DD HH:mm:s");
}

export const timeFormatter = date => {
    if (!date) return;
    return moment.utc(date, "DD-MM-YYYY HH:mm:ss").local().format("HH:mm A");
}

export const timeFormatter3 = time => {
    if (!time) return;
    return moment(time, "HH:mm:ss").format("hh:mm A");
}

export const dateTimeFormatterNew = date => {
    if (!date) return;
    return moment.utc(date, "DD-MM-YYYY HH:mm:ss").local().format("DD-MM-YYYY HH:mm:ss");
}

export const dateTimeFormatterNew2 = date => {
    if (!date) return;
    return moment.utc(date, "DD-MM-YYYY HH:mm:ss").local().format("DD-MM-YYYY HH:mm:ss");
}
export const dateTimeFormatterNew3 = date => {
    if (!date) return;
    return  moment.utc(date, "DD-MM-YYYY HH:mm:ss").local().format("DD MMM YYYY HH:mm A");
}

export const dateFormatterNew = date => {
    if (!date) return;
    return moment.utc(date, "DD-MM-YYYY HH:mm:ss").local().format("YYYY-MM-D");
}

export const dateFormatterNew2 = date => {
    if (!date) return;
    return moment.utc(date, "DD-MM-YYYY HH:mm:ss").local().format("YYYY-MM-DD");
}

export const timeFormatter2 = time => {
    if (!time) return;
    return moment(time, "HH:mm:ss").format("HH:mm:ss");
}

// export const timeFormatter2 = time => {
//     if (!time) return;
//     return moment.utc(time, "HH:mm:ss").local().format("HH:mm:ss");
// }

export const longToDateFormatterTwo = date => {
    if (!date) return;
    return moment(new Date(date), "DD-MM-YYYY HH:mm:ss").format("DD-MM-YYYY");
}

export const longDateTimeFormatter = date => {
    if (!date) return;
    const _date = new Date(date);
    return moment.utc(_date, "DD-MM-YYYY HH:mm:ss").local().format("DD MMM YYYY HH:mm A");
}

export const longDateFormatter = date => {
    if (!date) return;
    const _date = new Date(date);
    return moment.utc(_date, "DD-MM-YYYY HH:mm:ss").local().format("DD MMM YYYY");
}

export const dateToLongTo = date => {
    if (!date) return;
    return dateToLong(moment(moment.utc(date, "YYYY-MM-DD").local().format("YYYY-MM-DD 23:59")));
}


export const longToDate = date => {
    if (!date) return;
    return new Date(date);
}

export const dateToLong = date => {
    if (!date) return;
    return date*1;
}

export const dateTimeToLongFrom = date => {
    const _date = moment.utc(date, "YYYY-MM-DD").local().format("YYYY-MM-DD 00:02:00");
    // if (!date) return;
    return _date*1;
}

export const longTodate2 = date => {
    if (!date) return;
    return new Date(date);
}

export const longToDateTimeFormatter = date => {
    if (!date) return;
    return moment(new Date(date), "DD-MM-YYYY HH:mm:ss").format("DD MMM YYYY hh:mm A");
}

export const bookingIdFormatter = (bookingId, moduleType) => {
    if(!bookingId) return;
    return bookingId?.replace(`${moduleType}_`, "");
}

export const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
});

export const moneyFormatter = (value) => {
    return `${BD_TK_SIGN} ${formatter.format(value)}`;
}

export const enumFormatter = value => {
    if (!value) return;

    const words = value.toLowerCase().split("_");
    return words.map(value => value[0].toUpperCase() + value.substring(1))
        .join(" ");
}

export const validateEmail = email => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const showDateFormat = "DD-MM-YYYY";
export const sendDateFormat = "DD-MM-yyyy";

export const showDateTimeFormat = "DD-MM-YYYY HH:mm:ss";
export const sendDateTimeFormat = "DD-MM-yyyy HH:mm:ss";

export const getAuthHeaders = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    return {"Authorization": `Bearer ${accessToken}`};
}
