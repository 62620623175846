export const ROOT_PATH = '/';
export const DASHBOARD_PATH = `${ROOT_PATH}dashboard`;
export const USERS_PATH = `${ROOT_PATH}users`;

export const LOGIN_PATH = `${ROOT_PATH}login`;

export const FORGOT_PASSWORD = `${ROOT_PATH}forgot-password`;
export const OTP_PATH = `${ROOT_PATH}otp-verify`;
export const RESET_PASSWORD_PATH = `${ROOT_PATH}reset-password`;

// User
export const USER_LIST_VIEW_PATH = `${ROOT_PATH}users-list`;
export const USER_VIEW_PATH = `${ROOT_PATH}users`;
export const ADD_USER_PATH = `${ROOT_PATH}add-users`;
export const EDIT_USER_PATH = `${ROOT_PATH}edit-users`;
export const SELLER_PROFILE_EDIT_PATH = `${ROOT_PATH}edit-seller-profile`;
export const USER_PROFILE_PATH = `${ROOT_PATH}users-profile`

// Host
export const HOST_LIST_VIEW_PATH = `${ROOT_PATH}host`
export const HOST_REQUESTED_PATH = `${ROOT_PATH}host-requested`
export const HOST_APPROVE_PATH = `${ROOT_PATH}host-approve`
export const HOST_MESSAGE_PATH = `${ROOT_PATH}host-message`

// Packages
export const PACKAGES_LIST_VIEW_PATH = `${ROOT_PATH}packages`
export const ADD_PACKAGES_PATH = `${ROOT_PATH}add-packages`;
export const EDIT_PACKAGES_PATH = `${ROOT_PATH}edit-packages`;

// Subscriber
export const SUBSCRIBER_LIST_VIEW_PATH = `${ROOT_PATH}subscriber`;

// languages
// app
export const APP_LANGUAGE_PATH = `${ROOT_PATH}app-language`;
export const ADD_APP_LANGUAGE_PATH = `${ROOT_PATH}add-app-language`;
export const EDIT_APP_LANGUAGE_PATH = `${ROOT_PATH}edit-app-language`;
// web
// languages
export const WEB_LANGUAGE_PATH = `${ROOT_PATH}web-language`;
export const ADD_WEB_LANGUAGE_PATH = `${ROOT_PATH}add-web-language`;
export const EDIT_WEB_LANGUAGE_PATH = `${ROOT_PATH}edit-web-language`;

//role
export const ROLE_LIST_VIEW_PATH = `${ROOT_PATH}role-list`;
export const ADD_ROLE_PATH = `${ROOT_PATH}add-role`;
export const EDIT_ROLE_PATH = `${ROOT_PATH}edit-role`;

//settings
export const SETTINGS_FIELD_PATH = `${ROOT_PATH}field-settings`;
export const SETTINGS_EDIT_FIELD_PATH = `${ROOT_PATH}edit-field-settings`;

//Report
export const FINANCIAL_REPORT_PATH = `${ROOT_PATH}financial-report`;
export const SALES_SPACE_SHARE_PATH = `${ROOT_PATH}space-share-sales-report`;
export const SALES_RIDE_365_PATH = `${ROOT_PATH}ride-365-sales-report`;
export const SALES_ENTERPRISE_PATH = `${ROOT_PATH}enterprise-sales-report`;
export const PARK_REPORT_PATH = `${ROOT_PATH}park-sales-report`;
export const RENT_A_CAR_REPORT = `${ROOT_PATH}rent-sales-report`;

// Enlisted Property
// Space Share
export const SPACE_SHARE_PATH = `${ROOT_PATH}space-share-enlisted-property`;
export const SPACE_SHARE_VIEW_DETAILS_PATH = `${ROOT_PATH}space-share-enlisted-property-view-details`;
export const ENTERPRISE_VIEW_DETAILS_PATH = `${ROOT_PATH}enterprise-enlisted-property-view-details`;
//Park
export const RENT_PATH = `${ROOT_PATH}rent`;
export const RENT_VIEW_DETAILS_PATH = `${ROOT_PATH}rent-view-details`;
//Park
export const PARK_PATH = `${ROOT_PATH}park`;
export const PARK_VIEW_DETAILS_PATH = `${ROOT_PATH}park-view-details`;
// Enterprise
export const ENTERPRISE_PATH = `${ROOT_PATH}enterprise-enlisted-property`;
export const ENTERPRISE_ENLISTED_VIEW_DETAILS_PATH = `${ROOT_PATH}enterprise-enlisted-property-view-details`;

// Property types
//space share
export const SPACE_SHARE_TYPES_PATH = `${ROOT_PATH}space-share-types`;
export const CREATE_SPACE_SHARE_PROPERTY_TYPES = `${ROOT_PATH}create-space-share`;
export const EDIT_PROPERTY_TYPES_SPACE_SHARE = `${ROOT_PATH}edit-space-share-types`;
// Enterprise
export const ENTERPRISE_TYPES_PATH = `${ROOT_PATH}enterprise-types`;
export const CREATE_ENTERPRISE_PROPERTY_TYPES = `${ROOT_PATH}create-enterprise-types`;
export const EDIT_ENTERPRISE_PROPERTY_TYPES = `${ROOT_PATH}edit-enterprise-types`;
//ParkReport
export const GARAGE_PROPERTY_TYPES_PATH = `${ROOT_PATH}garage-types`;
export const CREATE_GARAGE_PROPERTY_TYPES_PATH = `${ROOT_PATH}create-garage`;
export const EDIT_PROPERTY_TYPES_GARAGE_PATH = `${ROOT_PATH}edit-garage`;
//Vehicle
export const VEHICLE_TYPES_PATH = `${ROOT_PATH}vehicle-types`;
export const CREATE_VEHICLE_PROPERTY_TYPES_PATH = `${ROOT_PATH}create-vehicle`;
export const EDIT_PROPERTY_TYPES_VEHICLE_PATH = `${ROOT_PATH}edit-vehicle`;

// Amenity Group
// space Share
export const AMENITY_SPACE_SHARE_PATH = `${ROOT_PATH}space-share-amenity-group`;
export const AMENITY_SPACE_SHARE_VIEW_DETAILS_PATH = `${ROOT_PATH}space-share-amenity-group-view-details`;
export const AMENITY_SPACE_SHARE_EDIT_PATH= `${ROOT_PATH}space-share-amenity-group-edit`;
export const AMENITY_SPACE_SHARE_ADD_PATH = `${ROOT_PATH}space-share-amenity-group-add`;
//Ride365
export const AMENITY_RIDE_365_PATH = `${ROOT_PATH}ride-365-amenity-group`;
export const AMENITY_RIDE_365_VIEW_DETAILS_PATH = `${ROOT_PATH}Ride365-amenity-group-view-details`;
export const AMENITY_RIDE_365_EDIT_PATH= `${ROOT_PATH}ride-365-amenity-group-edit`;
export const AMENITY_RIDE_365_ADD_PATH = `${ROOT_PATH}ride-365-amenity-group-add`;

export const AMENITY_ENTERPRISE_PATH = `${ROOT_PATH}enterprise-amenity-group`;
export const AMENITY_ENTERPRISE_VIEW_DETAILS_PATH = `${ROOT_PATH}enterprise-amenity-group-view-details`;
export const AMENITY_ENTERPRISE_EDIT_PATH= `${ROOT_PATH}enterprise-amenity-group-edit`;
export const AMENITY_ENTERPRISE_ADD = `${ROOT_PATH}enterprise-amenity-group-add`;

//Overview
export const OVERVIEW_PATH = `${ROOT_PATH}overview`;

// Promo Code
export const CREATE_PROMO_CODE_PATH = `${ROOT_PATH}create-promo-code`;
export const PROMO_CODE_PATH = `${ROOT_PATH}promo-code`;
export const EDIT_PROMO_CODE_PATH = `${ROOT_PATH}edit-promo-code`;

// Global Setting
export const GLOBAL_SETTINGS_PATH = `${ROOT_PATH}global-settings`;
export const CREATE_GLOBAL_SETTINGS_PATH = `${ROOT_PATH}create-global-settings-path`;
export const EDIT_GLOBAL_SETTING_PATH = `${ROOT_PATH}edit-global-settings`;

// BlogListView
export const CREATE_BLOG_PATH = `${ROOT_PATH}create-blog`;
export const BLOG_PATH = `${ROOT_PATH}blog`;
export const EDIT_BLOG_PATH = `${ROOT_PATH}edit-blog`;
export const VIEW_DETAILS_BLOG_PATH = `${ROOT_PATH}blog-viw-details`;

// BlogListView
export const EMERGENCY_LIST_VIEW_PATH = `${ROOT_PATH}emergency`;
export const CREATE_EMERGENCY_PATH = `${ROOT_PATH}create-emergency`;
export const EDIT_EMERGENCY_PATH = `${ROOT_PATH}edit-emergency`;

// Help And Support
export const HELP_AND_SUPPORT_PATH = `${ROOT_PATH}help-and-support`;
export const CREATE_HELP_AND_SUPPORT_PATH = `${ROOT_PATH}create-help-and-support`;
export const EDIT_HELP_AND_SUPPORT_PATH = `${ROOT_PATH}edit-help-and-support`;

// PRESS
export const PRESS_PATH = `${ROOT_PATH}press`;
export const CREATE_PRESS_PATH = `${ROOT_PATH}create-press`;
export const EDIT_PRESS_PATH = `${ROOT_PATH}edit-press`;

// RefundRequest AccommodationBookingServices
export const ACCOMMODATION_REFUND_REQUEST_PATH = `${ROOT_PATH}accommodation-refund-request-list`;
export const ACCOMMODATION_REFUND_REQUEST_VIEW_DETAILS_PATH = `${ROOT_PATH}accommodation-refund-request-view-details`;

// RefundRequest EntrepreneurRefundRequest
export const ENTREPRENEUR_REFUND_REQUEST_PATH = `${ROOT_PATH}entrepreneur-refund-request`;
export const ENTREPRENEUR_REFUND_REQUEST_VIEW_DETAILS_PATH = `${ROOT_PATH}entrepreneur-refund-request-view-details`;

// RefundRequest EntrepreneurRefundRequest
export const PARK_REFUND_REQUEST_PATH = `${ROOT_PATH}park-refund-request`;
export const PARK_REFUND_REQUEST_VIEW_DETAILS_PATH = `${ROOT_PATH}park-refund-request-view-details`;

export const RENT_BOOKING_HISTORY_PATH = `${ROOT_PATH}rent-booking-history-policy`;
export const RENT_BOOKING_HISTORY_VIEW_DETAILS_PATH = `${ROOT_PATH}rent-booking-history-view-details`;

// RefundRequest AccommodationBookingServices
export const ACCOMMODATION_BOOKING_HISTORY_PATH = `${ROOT_PATH}accommodation-booking-history-list`;
export const ACCOMMODATION_BOOKING_HISTORY_VIEW_DETAILS_PATH = `${ROOT_PATH}accommodation-booking-history-view-details`;

// RefundRequest EntrepreneurRefundRequest
export const ENTREPRENEUR_BOOKING_HISTORY_PATH = `${ROOT_PATH}entrepreneur-booking-history`;
export const ENTREPRENEUR_BOOKING_HISTORY_VIEW_DETAILS_PATH = `${ROOT_PATH}entrepreneur-booking-history-view-details`;

// RefundRequest EntrepreneurRefundRequest
export const PARK_BOOKING_HISTORY_PATH = `${ROOT_PATH}park-booking-history`;
export const PARK_BOOKING_HISTORY_VIEW_DETAILS_PATH = `${ROOT_PATH}park-booking-history-view-details`;


// COMMITNITY
export const COMMUNITY_PATH = `${ROOT_PATH}community`;
export const CREATE_COMMUNITY_PATH = `${ROOT_PATH}create-community`;
export const EDIT_COMMUNITY_PATH = `${ROOT_PATH}edit-community`;

// COMPANY DETAILS
export const COMPANY_DETAILS_PATH = `${ROOT_PATH}company-details`;
export const CREATE_COMPANY_DETAILS_PATH = `${ROOT_PATH}create-company-details`;
export const EDIT_COMPANY_DETAILS_PATH = `${ROOT_PATH}edit-company-details`;

// CAREER
export const CAREER_PATH = `${ROOT_PATH}career`;
export const CREATE_CAREER_PATH = `${ROOT_PATH}create-career`;
export const EDIT_CAREER_PATH = `${ROOT_PATH}edit-career`;

// CAREER APPLICATION
export const CAREER_APPLICATION_PATH = `${ROOT_PATH}career-application`;
export const CAREER_APPLICATION_VIEW_DETAILS_PATH = `${ROOT_PATH}career-application`;

// SITE MAP
export const CREATE_SITE_MAP_PATH = `${ROOT_PATH}create-site-map`;
export const SITE_MAP_PATH = `${ROOT_PATH}site-map`;
export const EDIT_SITE_MAP_PATH = `${ROOT_PATH}edit-site-map`;

// Report
export const REPORT_PROBLEM_PATH = `${ROOT_PATH}report`;
export const REPORT_PROBLEM_VIEW_PATH = `${ROOT_PATH}view-report`;

// Promotion
export const CREATE_PROMOTION_PATH = `${ROOT_PATH}create-promotion`;
export const PROMOTION_PATH = `${ROOT_PATH}promotion-map`;
export const EDIT_PROMOTION_PATH = `${ROOT_PATH}edit-promotion`;

// Contact Us
export const CONTACT_US_LIST_PATH = `${ROOT_PATH}conctact-us-list`;
export const CONTACT_US_LIST_VIEW_DETAILS = `${ROOT_PATH}contact-us-list-details`;

// ..............................Aditional Pages Start....................................

// Contact page
export const ABOUT_US_PATH = `${ROOT_PATH}about-us`;
// Contact Us
export const CONTACT_US_PATH = `${ROOT_PATH}contact-us`;
// Why Use page
export const WHY_USE_PATH = `${ROOT_PATH}why-use`;
// Looking For page
export const LOOKING_FOR_PATH = `${ROOT_PATH}looking-for`;
// Refund policy
export const REFUND_POLICY_PATH = `${ROOT_PATH}refund-policy`;
// Living With Us
export const LIVING_WITH_PATH = `${ROOT_PATH}living-with-us`;
// commoditySlider
export const COMMODITY_SLIDER = `${ROOT_PATH}commodity-slider`;
// Safety Policy
export const SAFETY_POLICY_PATH = `${ROOT_PATH}safety-policy`;
// Founder Latter
export const FOUNDER_LATTER_PATH = `${ROOT_PATH}founder_later`;
// how To BecomeHost
export const HOW_TO_BECOME_HOST = `${ROOT_PATH}how-to-become-host`;
// accommodation
export const ACCOMODATION_PATH = `${ROOT_PATH}accommodation`;
//Ride 365
export const RIDE_365_PAGE_PATH = `${ROOT_PATH}ride-365`;
// enterprise path
export const ENTERPRISE_PAGE_PATH = `${ROOT_PATH}enterprise-page`;
// support
export const SUPPORT_PATH = `${ROOT_PATH}support`;
// Safety Info
export const PRIVACY_POLICY_PATH = `${ROOT_PATH}safety-info`;
// Term Service
export const TERM_SERVICE_PATH = `${ROOT_PATH}term-service`;

// ..............................Additional Pages End....................................

export const PAGE_403_PATH = `${ROOT_PATH}403`;
export const PAGE_404_PATH = `${ROOT_PATH}404`;
export const PAGE_500_PATH = `${ROOT_PATH}500`;
