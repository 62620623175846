import React, {createContext, useState} from 'react';
import {Toast} from "../components/common/Toast";
import ChatService from "../services/ChatService";
import {getErrorMessage} from "../helpers/Utils";

export const ChatContext = createContext("ChatContext");

const ChatProvider = ({children}) => {

    const [chatLoading, setChatLoading] = useState(false);
    const [chatList, setChatList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);

    const getAllChat = async data => {
        try {
            setChatLoading(true);
            const res = await ChatService.getChatList(data);
            const reverseList = res.data.content.reverse();
            setChatList(reverseList);
            setTotalElements(res.data.totalElements);
            setChatLoading(false);
            return true;
        } catch (error) {
            setChatLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "ERROR", message);
            return false;
        }
    }

    const getChatMoreData = async data => {
        try {
            setChatLoading(true);
            const res = await ChatService.getChatList(data);
            const reverseList = res.data.content.reverse();
            setChatList([...reverseList, ...chatList]);
            setTotalElements(res.data.totalElements);
            setChatLoading(false);
            return true;
        } catch (error) {
            setChatLoading(false);
            const message = getErrorMessage(error);
            Toast("error", "ERROR", message);
            return false;
        }
    }

    return (
        <ChatContext.Provider
            value={{
                chatLoading,
                chatList,
                setChatList,
                totalElements,
                getChatMoreData,
                getAllChat,
            }}
        >
            {children}
        </ChatContext.Provider>
    );
}

export default ChatProvider;
