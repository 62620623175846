import axios from "axios";
import {
    ADD_PROPERTY_TYPES_GARAGE_PATH, DELETE_PROPERTY_TYPES_GARAGE_BY_ID_PATH,
    GET_PROPERTY_TYPES_GARAGE_BY_ID_PATH,
    GET_PROPERTY_TYPES_GARAGE_PATH, UPDATE_PROPERTY_TYPES_GARAGE_BY_ID_PATH
} from "../../../helpers/APIURL";

export default class GarageTypesService {

    static getGarageTypes(params) {
        return axios.get(GET_PROPERTY_TYPES_GARAGE_PATH, {params});
    }

    static addPGarageTypesPropertyTypes(data) {
        return axios.post(ADD_PROPERTY_TYPES_GARAGE_PATH, data);
    }


    static getGarageTypesById(id) {
        return axios.get(`${GET_PROPERTY_TYPES_GARAGE_BY_ID_PATH}/${id}`);
    }

    static editGarageTypesById(id, data) {
        return axios.put(`${UPDATE_PROPERTY_TYPES_GARAGE_BY_ID_PATH}/${id}`, data);
    }

    static deleteGarageTypes(id) {
        return axios.delete(`${DELETE_PROPERTY_TYPES_GARAGE_BY_ID_PATH}/${id}`);
    }
}