import React, {createContext, useState} from 'react';
import {Toast} from "../components/common/Toast";
import {getErrorMessage} from "../helpers/Utils";
import SinglePageContentService from "../services/SinglePageContent/SinglePageContentService";

export const SinglePageContext = createContext("SinglePageContext");

const SinglePageContextProvider = ({children}) => {

    const [singlePageContent, setSinglePageList] = useState([]);
    const [totalElements, setTotalElements] = useState(0);
    const [loadingSinglePageContent, setLoadingSinglePageContent] = useState(false);


    const getSinglePageContent = async (params) => {
        try {
            setLoadingSinglePageContent(true);
            const response = await SinglePageContentService.getSinglePageContent(params);
            setSinglePageList(response.data);
            setTotalElements(response.data.totalElements);
            setLoadingSinglePageContent(false);
        } catch (error) {
            setLoadingSinglePageContent(false);
            const message = getErrorMessage(error);
            Toast("error", "Error", message);
        }

    }

    return (
        <SinglePageContext.Provider
            value={{
                getSinglePageContent,
                singlePageContent,
                totalElements,
                loadingSinglePageContent,
            }}
        >
            {children}
        </SinglePageContext.Provider>
    );
}

export default SinglePageContextProvider;