const API_VERSION = '1.0.0';

const SPACE_SHARE = "space-share";

const ENTERPRISE = "enterprise";

const RIDE_365 = "ride-365";

// const RIDE_365 = "ride-365";

const {REACT_APP_BASE_URL} = process.env;

export const {REACT_APP_S3_BUCKET_URL} = process.env;
const {REACT_APP_S3_BUCKET_URL2} = process.env;

const {REACT_APP_GOOGLE_MAP_API_KEY} = process.env;

export const GOOGLE_MAP_API_KEY = REACT_APP_GOOGLE_MAP_API_KEY;
// export const TINY_MCE_API = "gj9ei06xxexf7bhbmdm2o1pnsudmnpn7nmfkzzmc1tjj41ku";
export const TINY_MCE_API = "ud1bs394t87p80k4cawvuk0zljkpi8fgryrmzvs7nl7z0qdx";


const {REACT_APP_HOST_URL} = process.env;

const {REACT_APP_RIDE_365_URL} = process.env;

const {REACT_APP_ENTERPRISE_URL} = process.env;

const API_URL = `${REACT_APP_BASE_URL}/api/v/${API_VERSION}`;

const API_URL_HOST = `${REACT_APP_HOST_URL}/api/v/${API_VERSION}/${SPACE_SHARE}`;

const API_URL_RIDE_365 = `${REACT_APP_RIDE_365_URL}/api/v/${API_VERSION}/${RIDE_365}`;

const API_URL_ENTERPRISE = `${REACT_APP_ENTERPRISE_URL}/api/v/${API_VERSION}/${ENTERPRISE}`;

// const API_URL_RIDE_365 = `${REACT_APP_ENTERPRISE_URL}/api/v/${API_VERSION}/${RIDE_365}`;

// S3 Bucket url

export const IMAGE_URL = API_URL;

const API_URL_IMAGE_UPLOAD = `${API_URL}/secured/aws`;

export const PUBLIC_API_URL = `${API_URL}/public`;
export const SECURED_API_URL = `${API_URL}/secured`;

export const SECURED_API_URL_HOST = `${API_URL_HOST}/secured`;

export const SECURED_API_URL_RIDE_365 = `${API_URL_RIDE_365}/secured`;

export const SECURED_API_URL_ENTERPRISE = `${API_URL_ENTERPRISE}/secured`;

export const S3_BUCKET_URL_OTHER_FILE = `${REACT_APP_S3_BUCKET_URL}`
export const S3_BUCKET_URL_ADVERTISEMENTS = `${REACT_APP_S3_BUCKET_URL2}`
export const RETRIEVE_IMAGE = `${REACT_APP_S3_BUCKET_URL}`;

export const USER_LOGIN_URL = `${PUBLIC_API_URL}/admin/login`;

export const RESET_PASSWORD = `${PUBLIC_API_URL}/users/request/reset-password-by-email`;

export const VERIFY_RESET_PASSWORD_URL = `${PUBLIC_API_URL}/users/verify/reset-password-by-email`;

export const UPLOAD_IMAGE = `${SECURED_API_URL}`;

export const UPLOAD_IMAGE_SINGLE_PAGE_CONTENT = `${SECURED_API_URL}/aws/upload/file`;


//chat socket
// export const SOCKET_CHAT_BASE_URL = "http://192.168.10.185:8080/ws";
export const SOCKET_CHAT_BASE_URL = `${REACT_APP_BASE_URL}/ws`;
export const SOCKET_HOST_ADMIN_CHAT_URL = "/app/private-message";
export const SOCKET_HOST_USER_TYPE = "HOST_USER";
export const SOCKET_MESSAGE_DATA_TYPE = "MESSAGE"

// transaction
export const GET_TRANSACTION_URL = `${SECURED_API_URL}/transactions`;
export const GET_TRANSACTION_GRAPH_URL = `${SECURED_API_URL}/transactions/graph`;

// Language
export const GET_LANGUAGE_URL = `${SECURED_API_URL}/languages/get-all`;
export const CREATE_LANGUAGE_URL = `${SECURED_API_URL}/languages/create`;
export const EDIT_LANGUAGE_URL = `${SECURED_API_URL}/languages/update`;
export const GET_LANGUAGE_BY_ID_URL = `${SECURED_API_URL}/languages/by-id`;

// Dashboard
export const GET_DASHBOARD_COUNT = `${SECURED_API_URL}/dashboard/all/count`;
export const GET_DASHBOARD_PIE_DATA = `${SECURED_API_URL}/dashboard/pie-chart/data/by-region`;
export const GET_DASHBOARD_GRAPH_DATA = `${SECURED_API_URL}/dashboard/graph/data`;

//report
export const GET_REPORT = `${SECURED_API_URL}/reports`;

// User
export const GET_USER_LIST = `${SECURED_API_URL}/users`;
export const GET_USER_PROFILE = `${SECURED_API_URL}/users/profile`;
export const GET_USER_BY_ID = `${SECURED_API_URL}/users/id`;
export const CREATE_USER_URL = `${SECURED_API_URL}/users/create`;
export const UPDATE_USER_URL = `${SECURED_API_URL}/users/update`;
export const CHANGE_PASSWORD_URL = `${SECURED_API_URL}/users/change-password`;
export const UPDATE_USER_PROFILE_URL = `${SECURED_API_URL}/users/profile`;

export const AUTH = "auth";

//chat
export const GET_ALL_CHAT = `${SECURED_API_URL}/chat/get-all`;

// Host
export const GET_HOST_REQUEST_URL = `${SECURED_API_URL}/users/requested-host`;
export const GET_HOST_LIST_URL = `${SECURED_API_URL}/users/all-hosts`;

export const HOST_APPROVE_URL = `${SECURED_API_URL}/users/approve-host`;
export const HOST_CANCEL_URL = `${SECURED_API_URL}/users/cancel/host-request`;

//address
export const CREATE_ADDRESS = `${SECURED_API_URL}/addresses/create`;
export const UPDATE_ADDRESS = `${SECURED_API_URL}/addresses/update`;
export const GET_ADDRESS_LIST = `${SECURED_API_URL}/addresses`;
export const GET_ADDRESS_BY_ID = `${SECURED_API_URL}/addresses/id`;
export const DELETE_ADDRESS = `${SECURED_API_URL}/addresses/delete`;
export const GET_PRIMARY_ADDRESS = `${SECURED_API_URL}/addresses/unique/primary-cities`;

// Promo Code
export const GET_PACKAGES_URL = `${SECURED_API_URL}/packages`;
export const GET_ACTIVE_PACKAGES_URL = `${SECURED_API_URL}/packages/get-active-list/for-admin`;
export const ADD_PACKAGES_URL = `${SECURED_API_URL}/packages/create`;
export const GET_PACKAGES_BY_ID_URL = `${SECURED_API_URL}/packages/id`;
export const GET_USER_PACKAGES_BY_ID_URL = `${SECURED_API_URL}/packages/get-all/user-packages`;
export const UPDATE_PACKAGES_BY_ID_URL = `${SECURED_API_URL}/packages/update`;
export const ADD_USER_PACKAGES_BY_ID_URL = `${SECURED_API_URL}/packages/add-to-user`;
export const DELETE_PACKAGES_BY_ID_PATH = `${SECURED_API_URL}/packages/disable`;

//role
export const GET_ROLE_LIST = `${SECURED_API_URL}/roles`;
export const GET_ROLE_BY_ID = `${SECURED_API_URL}/roles/id`;
export const POST_ROLE_URL = `${SECURED_API_URL}/roles/create`;
export const UPDATE_ROLE_URL = `${SECURED_API_URL}/roles/update`;
export const ALL_PERMISSION_URL = `${SECURED_API_URL}/roles/permissions`;

// Promo Code
export const GET_PROMO_CODE_URL = `${SECURED_API_URL}/promos`;
export const ADD_PROMO_CODE_URL = `${SECURED_API_URL}/promos/create`;
export const GET_PROMO_CODE_BY_ID_URL = `${SECURED_API_URL}/promos/id`;
export const UPDATE_PROMO_CODE_BY_ID_URL = `${SECURED_API_URL}/promos/update`;
export const DELETE_PROMO_CODE_BY_ID_URL = `${SECURED_API_URL}/promos/delete`;

// BlogListView
export const GET_BLOG_URL = `${SECURED_API_URL}/blogs/get-all`;
export const ADD_BLOG_URL = `${SECURED_API_URL}/blogs/create`;
export const GET_BLOG_BY_ID_URL = `${SECURED_API_URL}/blogs/get-by-id`;
export const UPDATE_BLOG_URL = `${SECURED_API_URL}/blogs/update`;
export const DELETE_BLOG_URL = `${SECURED_API_URL}/blogs/disabled`;

// Report Problem
export const GET_REPORT_PROBLEM_URL = `${SECURED_API_URL}/reports/get-all`;
export const GET_REPORT_PROBLEM_BY_ID_URL = `${SECURED_API_URL}/reports/get-by-id`;

// Emergency
export const GET_EMERGENCY_URL = `${SECURED_API_URL}/emergency-contacts/get-all`;
export const ADD_EMERGENCY_URL = `${SECURED_API_URL}/emergency-contacts/create`;
export const GET_EMERGENCY_BY_ID_URL = `${SECURED_API_URL}/emergency-contacts/get-by-id`;
export const UPDATE_EMERGENCY_URL = `${SECURED_API_URL}/emergency-contacts/update`;
export const DELETE_EMERGENCY_URL = `${SECURED_API_URL}/emergency-contacts/delete`;

// mandatory
export const GET_MANDATORY_FIELD_LIST = `${SECURED_API_URL}/mandatory-fields/user/fields`;
export const GET_MANDATORY_FIELD_LIST_BY_ALIAS = `${SECURED_API_URL}/mandatory-fields/role-alias`;
export const ADD_MANDATORY_FIELD_BY_ALIAS = `${SECURED_API_URL}/mandatory-fields/create`;
export const EDIT_MANDATORY_FIELD_BY_ALIAS = `${SECURED_API_URL}/mandatory-fields/update`;

// Global Settings
export const GET_GLOBAL_SETTINGS = `${SECURED_API_URL}/global-configs`;
export const ADD_GLOBAL_SETTINGS = `${SECURED_API_URL}/global-configs/create`;
export const GET_GLOBAL_SETTINGS_BY_ID = `${SECURED_API_URL}/global-configs/id`;
export const UPDATE_GLOBAL_SETTINGS_BY_ID = `${SECURED_API_URL}/global-configs/update`;

// Enlisted Property Space Share
export const GET_ENLISTED_PROPERTY_SPACE_SHARE = `${SECURED_API_URL_HOST}/advertisements/get-all`;
export const GET_ENLISTED_PROPERTY_SPACE_SHARE_BY_ID = `${SECURED_API_URL_HOST}/advertisements/get-by-id`;
export const UPDATE_ENLISTED_PROPERTY_SPACE_SHARE_BY_ID = `${SECURED_API_URL_HOST}/advertisements/update`;
export const DELETE_ENLISTED_PROPERTY_SPACE_SHARE_BY_ID = `${SECURED_API_URL_HOST}/advertisements/disable`;

// Ride 365 Rent
export const GET_RENT_URL = `${SECURED_API_URL_RIDE_365}/advertisement/rents/get-all`;
export const GET_RENT_BY_ID_URL = `${SECURED_API_URL_RIDE_365}/advertisement/rents/get/by-id`;
export const UPDATE_RENT_BY_ID = `${SECURED_API_URL_RIDE_365}/advertisement/rents/update`;
export const DELETE_RENT_BY_ID = `${SECURED_API_URL_RIDE_365}/advertisement/rentss/disable`;

// Ride 365 P{ark
export const GET_PARK_URL = `${SECURED_API_URL_RIDE_365}/advertisement/parks/get-all`;
export const GET_PARK_BY_ID_URL = `${SECURED_API_URL_RIDE_365}/advertisement/parks/get/by-id`;
export const UPDATE_PARK_BY_ID = `${SECURED_API_URL_RIDE_365}/advertisement/parks/update`;
export const DELETE_PARK_BY_ID = `${SECURED_API_URL_RIDE_365}/advertisement/parks/disable`;

//ride 365 amenity
export const GET_ALL_RIDE_365_AMENITY_GROUP = `${SECURED_API_URL_RIDE_365}/amenity-groups/get-all`;

// Enlisted Property Enterprise
export const GET_ENLISTED_PROPERTY_ENTERPRISE = `${SECURED_API_URL_ENTERPRISE}/advertisements/get-all`;
export const GET_ENTERPRISE_BY_ID = `${SECURED_API_URL_ENTERPRISE}/advertisements/get/by-id`;
export const UPDATE_ENTERPRISE_BY_ID = `${SECURED_API_URL_ENTERPRISE}/advertisements/update`;
export const DELETE_ENTERPRISE_BY_ID = `${SECURED_API_URL_ENTERPRISE}/advertisements/disable`;

// Property Types
// Space Share Property Types
export const GET_PROPERTY_TYPES_SPACE_SHARE = `${SECURED_API_URL_HOST}/advertisement/types/get-all`;
export const GET_PROPERTY_TYPES_SPACE_SHARE_BY_ID = `${SECURED_API_URL_HOST}/advertisement/types/get/by-id`;
export const ADD_PROPERTY_TYPES_SPACE_SHARE = `${SECURED_API_URL_HOST}/advertisement/types/create`;
export const UPDATE_PROPERTY_TYPES_SPACE_SHARE_BY_ID = `${SECURED_API_URL_HOST}/advertisement/types/update`;
export const DESABLE_PROPERTY_TYPES_SPACE_SHARE_BY_ID = `${SECURED_API_URL_HOST}/advertisement/types/disable`;
export const DELETE_PROPERTY_TYPES_SPACE_SHARE_BY_ID = `${SECURED_API_URL_HOST}/advertisement/types/delete`;
// Enterprise Property Types
export const GET_PROPERTY_TYPES_ENTERPRISE = `${SECURED_API_URL_ENTERPRISE}/advertisement/types/get-all`;
export const GET_PROPERTY_TYPES_ENTERPRISE_BY_ID = `${SECURED_API_URL_ENTERPRISE}/advertisement/types/get/by-id`;
export const ADD_PROPERTY_TYPES_ENTERPRISE = `${SECURED_API_URL_ENTERPRISE}/advertisement/types/create`;
export const UPDATE_PROPERTY_TYPES_ENTERPRISE_BY_ID = `${SECURED_API_URL_ENTERPRISE}/advertisement/types/update`;
export const DESABLE_PROPERTY_TYPES_ENTERPRISE_BY_ID = `${SECURED_API_URL_ENTERPRISE}/advertisement/types/disable`;
export const DELETE_PROPERTY_TYPES_ENTERPRISE_BY_ID = `${SECURED_API_URL_ENTERPRISE}/advertisement/types/delete`;
// ParkReport Property Types
export const GET_PROPERTY_TYPES_GARAGE_PATH = `${SECURED_API_URL_RIDE_365}/garage-types/get-all`;
export const GET_PROPERTY_TYPES_GARAGE_BY_ID_PATH = `${SECURED_API_URL_RIDE_365}/garage-types/get/by-id`;
export const ADD_PROPERTY_TYPES_GARAGE_PATH = `${SECURED_API_URL_RIDE_365}/garage-types/create`;
export const UPDATE_PROPERTY_TYPES_GARAGE_BY_ID_PATH = `${SECURED_API_URL_RIDE_365}/garage-types/update`;
export const DELETE_PROPERTY_TYPES_GARAGE_BY_ID_PATH = `${SECURED_API_URL_RIDE_365}/garage-types/disable`;
// Vehicle Property Types
export const GET_PROPERTY_TYPES_VEHICLE_PATH = `${SECURED_API_URL_RIDE_365}/vehicle-types/get-all`;
export const GET_PROPERTY_TYPES_VEHICLE_BY_ID_PATH = `${SECURED_API_URL_RIDE_365}/vehicle-types/get/by-id`;
export const ADD_PROPERTY_TYPES_VEHICLE_PATH = `${SECURED_API_URL_RIDE_365}/vehicle-types/create`;
export const UPDATE_PROPERTY_TYPES_VEHICLE_BY_ID_PATH = `${SECURED_API_URL_RIDE_365}/vehicle-types/update`;
export const DELETE_PROPERTY_TYPES_VEHICLE_BY_ID_PATH = `${SECURED_API_URL_RIDE_365}/vehicle-types/disable`;


// Amenity Group Space Share
export const GET_AMENITY_GROUP_SPACE_SHARE = `${SECURED_API_URL_HOST}/amenity-groups/get-all`;
export const GET_AMENITY_GROUP_SPACE_SHARE_BY_ID = `${SECURED_API_URL_HOST}/amenity-groups/by-id`;
export const UPDATE_AMENITY_GROUP_SPACE_SHARE_BY_ID = `${SECURED_API_URL_HOST}/amenity-groups/update`;
export const CREATE_AMENITY_GROUP_SPACE_SHARE = `${SECURED_API_URL_HOST}/amenity-groups/create`;

// Amenity Group Space Share
export const GET_AMENITY_GROUP_RIDE_365 = `${SECURED_API_URL_RIDE_365}/amenity-groups/get-all`;
export const GET_AMENITY_GROUP_RIDE_365_BY_ID = `${SECURED_API_URL_RIDE_365}/amenity-groups/by-id`;
export const UPDATE_AMENITY_GROUP_RIDE_365_BY_ID = `${SECURED_API_URL_RIDE_365}/amenity-groups/update`;
export const CREATE_AMENITY_RIDE_365_SHARE = `${SECURED_API_URL_RIDE_365}/amenity-groups/create`;

// Amenity Group Space Share
export const GET_AMENITY_GROUP_ENTERPRISE_URL = `${SECURED_API_URL_ENTERPRISE}/amenity-groups/get-all`;
export const GET_AMENITY_GROUP_ENTERPRISE_BY_ID = `${SECURED_API_URL_ENTERPRISE}/amenity-groups/by-id`;
export const UPDATE_AMENITY_GROUP_ENTERPRISE_BY_ID = `${SECURED_API_URL_ENTERPRISE}/amenity-groups/update`;
export const CREATE_AMENITY_ENTPRISE = `${SECURED_API_URL_ENTERPRISE}/amenity-groups/create`;

// Help And Support
export const GET_HELP_AND_SUPPORT_URL = `${SECURED_API_URL}/help-supports/get-all`;
export const ADD_HELP_AND_SUPPORT_URL = `${SECURED_API_URL}/help-supports/create`;
export const GET_HELP_AND_SUPPORT_BY_ID_URL = `${SECURED_API_URL}/help-supports/get-by-id`;
export const UPDATE_HELP_AND_SUPPORT_BY_ID_PATH_URL = `${SECURED_API_URL}/help-supports/update`;
export const DELETE_HELP_AND_SUPPORT_BY_ID_PATH_URL = `${SECURED_API_URL}/help-supports/delete`;

// PRESS
export const GET_PRESS_URL = `${SECURED_API_URL}/presses/get-all`;
export const ADD_PRESS_URL = `${SECURED_API_URL}/presses/create`;
export const GET_PRESS_BY_ID_URL = `${SECURED_API_URL}/presses/get-by-id`;
export const UPDATE_PRESS_BY_ID_URL = `${SECURED_API_URL}/presses/update`;
export const DELETE_PRESS_URL = `${SECURED_API_URL}/presses/delete`;

// COMMUNITY
export const GET_COMMUNITY_URL = `${SECURED_API_URL}/communities/get-all`;
export const ADD_COMMUNITY_URL = `${SECURED_API_URL}/communities/create`;
export const GET_COMMUNITY_BY_ID_URL = `${SECURED_API_URL}/communities/get-by-id`;
export const UPDATE_COMMUNITY_URL = `${SECURED_API_URL}/communities/update`;
export const DELETE_COMMUNITY_URL = `${SECURED_API_URL}/communities/delete`;

// COMPANY_DETAILS
export const GET_COMPANY_DETAILS_URL = `${SECURED_API_URL}/company-details/get-all`;
export const ADD_COMPANY_DETAILS_URL = `${SECURED_API_URL}/company-details/create`;
export const GET_COMPANY_DETAILS_BY_ID_URL = `${SECURED_API_URL}/company-details/get-by-id`;
export const UPDATE_COMPANY_DETAILS_URL = `${SECURED_API_URL}/company-details/update`;
export const DELETE_COMPANY_DETAILS_URL = `${SECURED_API_URL}/company-details/delete`;

// Career
export const GET_CAREER_URL = `${SECURED_API_URL}/careers/get-all`;
export const ADD_CAREER_URL = `${SECURED_API_URL}/careers/create`;
export const GET_CAREER_BY_ID = `${SECURED_API_URL}/careers/get-by-id`;
export const UPDATE_CAREER_BY_ID = `${SECURED_API_URL}/careers/update`;
export const DELETE_CAREER_BY_ID = `${SECURED_API_URL}/careers/disabled`;

// MODULE TYPES
export const GET_MODULE_TYPES_URL = `${PUBLIC_API_URL}/module-types`;

// Career
export const GET_CAREER_APPLICATION_URL = `${SECURED_API_URL}/career-applications/get-all`;
export const GET_CAREER_APPLICATION_BY_ID = `${SECURED_API_URL}/career-applications/get-by-id`;
export const DELETE_CAREER_APPLICATION_BY_ID = `${SECURED_API_URL}/career-applications/delete`;

// SITE MAP
export const GET_SITE_MAP_URL = `${SECURED_API_URL}/site-map-groups/get-all`;
export const ADD_SITE_MAP_URL = `${SECURED_API_URL}/site-map-groups/create`;
export const GET_SITE_MAP_URL_BY_ID = `${SECURED_API_URL}/site-map-groups/get-by-id`;
export const UPDATE_SITE_MAP_URL_BY_ID = `${SECURED_API_URL}/site-map-groups/update`;
export const DELETE_SITE_MAP_URL_BY_ID = `${SECURED_API_URL}/site-map-groups/delete`;

// PROMOTION
export const GET_PROMOTION_URL = `${SECURED_API_URL}/promotion-groups/get-all`;
export const ADD_PROMOTION_URL = `${SECURED_API_URL}/promotion-groups/create`;
export const GET_PROMOTION_URL_BY_ID = `${SECURED_API_URL}/promotion-groups/get-by-id`;
export const UPDATE_PROMOTION_URL_BY_ID = `${SECURED_API_URL}/promotion-groups/update`;
export const DELETE_PROMOTION_URL_BY_ID = `${SECURED_API_URL}/promotion-groups/delete`;

// Additional Info(Single Page Content)
export const GET_SINGLE_PAGE_CONTENT = `${SECURED_API_URL}/single-page-contents/get`;
export const UPDATE_SINGLE_PAGE_CONTENT = `${SECURED_API_URL}/single-page-contents/update`;

// Contact Us
export const GET_CONTACT_US_LIST_URL = `${SECURED_API_URL}/contact-us/get-all`;
export const GET_CONTACT_US_LIST_BY_ID_URL = `${SECURED_API_URL}/contact-us/get-by-id`;
export const DELETE_CONTACT_US_URL = `${SECURED_API_URL}/contact-us/delete`;

// Subscriber
export const GET_SUBSCRIBER_URL = `${SECURED_API_URL}/subscribers/get-all`;

//Accommodation RefundRequest
export const BOOKING_ACCOMMODATION_LIST_VIEW_URL = `${SECURED_API_URL_HOST}/bookings/get-all`;
export const BOOKING_ACCOMMODATION_COUNT_URL = `${SECURED_API_URL_HOST}/bookings/super-admin/count`;
export const GET_BOOKING_ACCOMMODATION_STATUS_BY_ID_URL = `${SECURED_API_URL_HOST}/bookings/get-by-id`;
export const GET_BOOKING_ACCOMMODATION_STATE_BY_ID_URL = `${SECURED_API_URL_HOST}/booking-states/get-all/by-booking-id`;
export const UPDATE_BOOKING_ACCOMMODATION_STATE_BY_ID_URL = `${SECURED_API_URL_HOST}/booking-states/update`;

// sales & commission
export const SALES_AND_COMMISSION_ACCOMMODATION_LIST_VIEW_URL = `${SECURED_API_URL_HOST}/bookings/get-all/for-sale-reports`;
export const ENTREPRENEUR_SALES_AND_COMMISSION_REPORT_PATH = `${SECURED_API_URL_ENTERPRISE}/bookings/get-all/for-sale-reports`;
export const PARK_SALES_AND_COMMISSION_REPORT = `${SECURED_API_URL_RIDE_365}/park-bookings/get-all/for-sale-reports`;
export const SALES_AND_COMMISSION__REPORT = `${SECURED_API_URL_RIDE_365}/rent-bookings/get-all/for-sale-reports`;


// Refund
export const GET_ACCOMMODATION_REFUND_REQUEST_URL = `${SECURED_API_URL_HOST}/bookings/get-all/refund-requests`;
export const GET_PARK_REFUND_REQUEST_URL = `${SECURED_API_URL_RIDE_365}/park-bookings/get-all/refund-requests`;
export const GET_ENTERPRISE_REFUND_REQUEST_URL = `${SECURED_API_URL_ENTERPRISE}/bookings/get-all/refund-requests`;

// enterprise Report
export const BOOKING_ENTERPRISE_REPORT_PATH = `${SECURED_API_URL_ENTERPRISE}/bookings/get-all`;
export const BOOKING_ENTERPRISE_REPORT_COUNT = `${SECURED_API_URL_ENTERPRISE}/bookings/super-admin/count`;
export const GET_BOOKING_ENTERPRISE_STATUS_BY_ID_URL = `${SECURED_API_URL_ENTERPRISE}/bookings/get-by-id`;
export const GET_BOOKING_ENTERPRISE_STATE_BY_ID_URL = `${SECURED_API_URL_ENTERPRISE}/booking-states/get-all/by-booking-id`;

export const UPDATE_BOOKING_ENTERPRISE_STATE_BY_ID_URL = `${SECURED_API_URL_ENTERPRISE}/booking-states/update`;

// Park Report
export const BOOKING_PARK_REPORT = `${SECURED_API_URL_RIDE_365}/park-bookings/get-all`;
export const BOOKING_PARK_REPORT_COUNT = `${SECURED_API_URL_RIDE_365}/park-bookings/super-admin/count`;
export const GET_BOOKING_PARK_STATUS_BY_ID_URL = `${SECURED_API_URL_RIDE_365}/park-bookings/get-by-id`;
export const GET_BOOKING_PARK_STATE_BY_ID_URL = `${SECURED_API_URL_RIDE_365}/park-booking-states/get-all/by-booking-id`;
export const UPDATE_BOOKING_PARK_STATE_BY_ID_URL = `${SECURED_API_URL_RIDE_365}/park-booking-states/update`;


export const GET_ALL_PUBLIC_RIDE_365_PARK = `${SECURED_API_URL_RIDE_365}/parks/advertisement/get-all`;


// Rent Report
export const BOOKING_RENT_REPORT = `${SECURED_API_URL_RIDE_365}/rent-bookings/get-all`;
export const BOOKING_RENT_REPORT_COUNT = `${SECURED_API_URL_RIDE_365}/rent-bookings/super-admin/count`;
export const GET_RENT_STATUS_BY_ID_URL = `${SECURED_API_URL_RIDE_365}/rent-bookings/get-by-id`;
export const GET_RENT_PARK_STATE_BY_ID_URL = `${SECURED_API_URL_RIDE_365}/rent-booking-states/get-all/by-booking-id`;
export const UPDATE_RENT_PARK_STATE_BY_ID_URL = `${SECURED_API_URL_RIDE_365}/rent-booking-states/update`;


// Token & Profile
export const ACCESS_TOKEN = 'nagaley-token';
export const PROFILE = "nagaley-profile";

//image upload
export const UPLOAD_PROFILE_PICTURE = `${SECURED_API_URL}/profile-pic/update`;
export const UPLOAD_NID_BACK_PICTURE = `${SECURED_API_URL}/nid-back-pic/upload`;
export const UPLOAD_NID_FRONT_PICTURE = `${SECURED_API_URL}/nid-front-pic/upload`;

//user image upload
export const USER_IMAGE_UPLOAD_URL = `${API_URL_IMAGE_UPLOAD}/user-pic/upload`;

//advertise image upload
export const ADVERTISE_IMAGE_UPLOAD_PATH = `${API_URL_IMAGE_UPLOAD}/advertisement/pic/upload`;

// google map
export const GET_LOCATION_ADDRESS = `https://maps.googleapis.com/maps/api/geocode/json`

// Currency
export const BD_TK_SIGN = "৳ ";

//color
export const RED = "#dc3545";
export const GREEN = "#28a745";
export const YELLOW = "#ffc107";

//back button color
export const BACK_BUTTON_COLOR = "#3390FF";

// Gender
export const GENDER_COLOR = {
    MALE: "blue",
    FEMALE: "green",
    OTHERS: "purple"
}

export const USERS_REPORT_COLOR = {
    Active: GREEN,
    Disabled: "#787878",
    Banned: RED,
    Male: GENDER_COLOR.MALE,
    Female: "#eb52a1",
    Other: GENDER_COLOR.OTHERS
}

export const PICTURE_TYPE = {
    FRONT: "FRONT",
    LEFT: "LEFT",
    RIGHT: "RIGHT",
    BACK: "BACK",
}

export const PICTURE_MODEL_TYPE = {
    PROFILE: "PROFILE",
    NID: "NID",
    PASSPORT: "PASSPORT",
}

export const ORDER_STATUS_COLOR = {
    "PENDING": "#FF5F00",
    "CONFIRMED": "#169BD7",
    "PAYING": "#99CC00",
    "ACTIVE": "#FFCC00",
    "COMPLETED": "#009A00",
    "CANCELLED": RED,
    "END": "#F50081",
}

export const ORDER_STATUS_SEQUENCE = {
    PENDING: "PAYING",
    PAYING: "CONFIRMED",
    CONFIRMED: "ACTIVE",
    ACTIVE: "CANCELLED",
    CANCELLED: "REFUNDED",
}
export const PENDING = "PENDING";
export const PAYING = "PAYING";
export const CONFIRMED = "CONFIRMED";
export const ACTIVE = "ACTIVE";
export const COMPLETED = "COMPLETED";
export const CANCELLED = "CANCELLED";
export const END = "END";
export const REFUNDED = "REFUNDED";

export const ACCOMMODATION_MODULE = "ACCOMMODATION";
export const ENTREPRENEUR_MODULE = "ENTREPRENEUR";
export const PARK_MODULE = "PARK";
export const RENT_MODULE = "RENT";